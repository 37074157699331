import React from 'react'
import { Spin, Typography } from 'antd'
import { Switch, Route } from 'react-router-dom'

import { useSandboxAuth } from './clients/lenarge'
import { ServiceDrawerProvider, WorkerOverviewDrawerProvider } from './components/maintenance'

import { WorkersOfPlaceByStatus } from './screens/WorkersOfPlaceByStatus'
import { MaintenanceServicesOfPlaceKanban } from './screens/MaintenanceServicesOfPlaceKanban'
import { TrucksDemandFlow } from './screens/TrucksDemandFlow'
import { LogisticDemands } from './screens/LogisticDemands'
import { MaintenanceServicesByVehicleTable } from './screens/MaintenanceServicesByVehicleTable'
// import { MaintenanceServicesTable } from './screens/MaintenanceServicesTable'
import { Reports } from './screens/Reports'

const App: React.FC = () => {
  const [, loadingAuth] = useSandboxAuth()

  return (
    <div className="app-body">
      {loadingAuth && (
        <div className="loading-spin">
          <Spin />
        </div>
      )}
      <ServiceDrawerProvider>
        <WorkerOverviewDrawerProvider>
          <Switch>
            <Route path="/" exact>
              <Typography.Title>Home</Typography.Title>
            </Route>
            <Route path="/places/:placeId/maintenance/workers" exact>
              <WorkersOfPlaceByStatus />
            </Route>
            <Route path="/places/:placeId/maintenance/services" exact>
              <MaintenanceServicesOfPlaceKanban />
            </Route>
            {/* <Route path="/maintenance/services">
              <MaintenanceServicesTable />
            </Route> */}
            <Route path="/maintenance/vehicles">
              <MaintenanceServicesByVehicleTable />
            </Route>
            <Route path="/truck-demands" exact>
              <TrucksDemandFlow />
            </Route>
            <Route path="/logistic-demands" exact>
              <LogisticDemands />
            </Route>
            <Route path="/reports" exact>
              <Reports />
            </Route>
            <Route path="/">
              <Typography.Title>Página não encontrada</Typography.Title>
            </Route>
          </Switch>
        </WorkerOverviewDrawerProvider>
      </ServiceDrawerProvider>
    </div>
  )
}

export default App
