import * as React from 'react'
import { Spin } from 'antd'
import { keys } from 'lodash'

import { demands } from '../../clients/lenarge'
import { TruckDemandWidget, AgentDemandCreator } from '../../components/demand'

export const ActiveTruckDemandCell: React.FC<{ truckId: string }> = ({ truckId }) => {
  const [truckDemands, loading] = demands.useTruckDemands(truckId, { status: 'EXECUTING' })
  const demandId = React.useMemo(() => keys(truckDemands)[0], [truckDemands])
  const demand = React.useMemo(() => (truckDemands ? truckDemands[demandId] : undefined), [truckDemands, demandId])

  if (loading)
    return (
      <div>
        <Spin />
      </div>
    )
  if (!demand) return <AgentDemandCreator truckId={truckId} initialStatus="EXECUTING" />
  return <TruckDemandWidget demandId={demandId} demand={demand} />
}
