import * as React from 'react'
import moment from 'moment'
import { ProjectOutlined } from '@ant-design/icons'
import { Spin, Timeline, DatePicker, Empty, Divider } from 'antd'
import { map, sortBy, groupBy, size } from 'lodash'

import lenarge, { maintenance, vehicles, iotReps } from '../../clients/lenarge'
import { ResponsibleDrawer } from '../drawer'
import { HumanizeFromNow, DateAndHour } from '../moment'

import './WorkerOverviewDrawer.scss'
import { useServiceDrawer } from './ServiceDrawer'

export const WorkerOverviewDrawerProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<{ workerId?: string; showing?: boolean; zIndex?: number }>({})
  const showWorkerOverviewDrawer = React.useCallback(
    (workerId: string, zIndex?: number) => {
      setState({ workerId, zIndex, showing: true })
    },
    [setState]
  )
  const closeWorkerOverviewDrawer = React.useCallback(() => {
    setState((currentState) => ({ ...currentState, showing: false }))
  }, [setState])
  return (
    <WorkerOverviewDrawerContext.Provider value={{ showWorkerOverviewDrawer, closeWorkerOverviewDrawer }}>
      {children}
      {state.workerId && (
        <WorkerOverviewDrawer workerId={state.workerId} showing={state.showing} zIndex={state.zIndex} onClose={closeWorkerOverviewDrawer} />
      )}
    </WorkerOverviewDrawerContext.Provider>
  )
}

export type WorkerOverviewDrawerHook = WorkerOverviewDrawerContextValue
export function useWorkerOverviewDrawer(): WorkerOverviewDrawerHook {
  return React.useContext(WorkerOverviewDrawerContext)
}

interface WorkerOverviewDrawerProps {
  showing?: boolean
  workerId: string
  onClose: () => void
  zIndex?: number
}
const WorkerOverviewDrawer: React.FC<WorkerOverviewDrawerProps> = ({ workerId, showing, onClose, zIndex }) => {
  const [worker, loadingWorker] = maintenance.useMaintenanceWorker(workerId)
  const statusMeta = worker && lenarge.maintenance().getWorkerStatusMeta(worker.status)

  const serviceId = worker?.workingServiceId
  const [service, loadingService] = maintenance.useMaintenanceService(serviceId)

  const [vehicle, loadingVehicle] = vehicles.useVehicle(service?.vehicleId, service?.vehicleKind)
  const vehicleKindMeta = service && lenarge.vehicles().getVehicleKindMeta(service.vehicleKind)

  const loading = loadingWorker || loadingService || loadingVehicle

  return (
    <ResponsibleDrawer
      title={`Executor: ${worker?.firstName} ${worker?.surname}`}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={showing}
      zIndex={zIndex}
      className="worker-overview-drawer"
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <p>
            {'Status: '}
            {statusMeta?.label}
            {' ('}
            <HumanizeFromNow time={worker?.statusChangeTime} />
            {' - '}
            <DateAndHour time={worker?.statusChangeTime} />
            {')'}
          </p>
          {service && (
            <p>
              {'Serviço: '}
              {service.rdprServiceCod}
              {'/'}
              {service.rdprFilialNumber}
              {vehicle && ` - ${vehicle.licensePlate}`}
              {vehicleKindMeta && ` ${vehicleKindMeta.label}`}
            </p>
          )}
          <Divider />
          <WorkerTimeline workerId={workerId} pis={worker?.pis} />
        </>
      )}
    </ResponsibleDrawer>
  )
}

const WorkerTimeline: React.FC<{ workerId: string; pis?: number }> = ({ workerId, pis }) => {
  const [dayMoment, setDayMoment] = React.useState(moment().startOf('day'))
  const startTime = React.useMemo(() => moment(dayMoment).startOf('day').valueOf(), [dayMoment])
  const endTime = React.useMemo(() => moment(dayMoment).endOf('day').valueOf(), [dayMoment])
  const handleDayMomentChange = React.useCallback((selectedMoment?: moment.Moment | null) => setDayMoment(selectedMoment || moment()), [
    setDayMoment,
  ])

  const [timeline, loadingTimeline] = maintenance.useMaintenanceTimelineOfWorker(workerId, { startTime, endTime })
  const [repEntries, loadingRepEntries] = iotReps.useIotRepTimeEntriesOfPis(pis, { startTime, endTime })

  const { showServiceDrawer } = useServiceDrawer()

  const groupedEvents = React.useMemo(() => {
    const timelineEvents = map(timeline, (event, eventId) => ({
      time: event.time,
      description: `${lenarge.maintenance().getTimelineEventMeta(event.eventType).label}${event.description ? ': ' + event.description : ''}`,
      eventId,
      eventType: 'maintenance_timeline',
      handleOpenServiceClick: !!event.serviceId
        ? () => {
            if (event.serviceId) showServiceDrawer(event.serviceId)
          }
        : undefined,
    }))
    const repEntryEvents = map(repEntries, (event, eventId) => ({
      time: event.time,
      description: 'Bate o ponto',
      eventId,
      eventType: 'rep_time_entry',
      handleOpenServiceClick: undefined,
    }))
    const sortedEvents = sortBy([...timelineEvents, ...repEntryEvents], 'time', 'asc')
    return groupBy(sortedEvents, (event) => moment(event.time).format('HH:mm'))
  }, [timeline, repEntries, showServiceDrawer])

  const loading = loadingTimeline || loadingRepEntries

  return (
    <div className="worker-overview-drawer-timeline">
      <div className="timeline-filters">
        {'Timeline: '}
        <DatePicker format="ddd, DD/MM/YYYY" value={dayMoment} onChange={handleDayMomentChange} />
      </div>
      {loading || size(groupedEvents) > 0 ? (
        <Timeline reverse pending={loading}>
          {map(groupedEvents, (events, time) => (
            <Timeline.Item key={time}>
              {time}
              {': '}
              {map(events, (event, index) => (
                <span key={index}>
                  {event.description}
                  {event.handleOpenServiceClick && <ProjectOutlined onClick={event.handleOpenServiceClick} />}
                </span>
              ))}
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Empty description="Não há dados deste dia!" />
      )}
    </div>
  )
}

interface WorkerOverviewDrawerContextValue {
  showWorkerOverviewDrawer: (workerId: string, zIndex?: number) => void
  closeWorkerOverviewDrawer: () => void
}
const WorkerOverviewDrawerContext = React.createContext<WorkerOverviewDrawerContextValue>({
  showWorkerOverviewDrawer: () => {},
  closeWorkerOverviewDrawer: () => {},
})
