import * as React from 'react'
import { map } from 'lodash'
// import { ApartmentOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/lib/table'
import { MaintenanceService, Truck, Trailer } from '@lenarge/db-schema'
import { ServiceStatusMeta } from '@lenarge/api/lib/modules/maintenance/rtdb'

import lenarge from '../../clients/lenarge'
// import { LastTruckTraceCell } from '../../components/vehicle'
import { ServiceCodeCell, ServiceSectorCell } from '../../components/maintenance'

export interface TableDataRow extends MaintenanceService {
  serviceId: string
  truck?: Truck
  trailer?: Trailer
  statusMeta: ServiceStatusMeta
  classificationCode?: number
}
export const columns: ColumnProps<TableDataRow>[] = [
  // {
  //   title: <ApartmentOutlined style={{ marginLeft: 4 }} />,
  //   dataIndex: 'group',
  //   key: 'group',
  //   width: 40,
  //   fixed: 'left',
  //   render: () => (
  //     <div className="group-cell">
  //       <p>RODOV</p>
  //     </div>
  //   ),
  // },
  {
    title: 'Placa',
    dataIndex: 'licensePlate',
    key: 'licensePlate',
    width: 90,
    // fixed: 'left',
    sorter: (a, b) => {
      const aLicensePlate = a.truck?.licensePlate || a.trailer?.licensePlate || a.vehicleId
      const bLicensePlate = b.truck?.licensePlate || b.trailer?.licensePlate || b.vehicleId
      return aLicensePlate.localeCompare(bLicensePlate)
    },
    filters: [
      { value: 'truck', text: 'Cavalo' },
      { value: 'trailer', text: 'Carreta' },
    ],
    onFilter: (vehicleKind: string | number | boolean, service) => service.vehicleKind === vehicleKind,
    defaultSortOrder: 'ascend',
    render: (field, service) => (
      <div className="truck-cell">
        <p className="license-plate">{service.truck?.licensePlate || service.trailer?.licensePlate || service.vehicleId}</p>
        <p className="description">{lenarge.vehicles().getVehicleKindMeta(service.vehicleKind).label}</p>
      </div>
    ),
  },
  {
    title: 'Rdpr Cód.',
    dataIndex: 'rdprServiceCod',
    key: 'rdprServiceCod',
    width: 120,
    sorter: (a, b) => (b.sectorsFinishTime || b.statusChangeTime) - (a.sectorsFinishTime || a.statusChangeTime),
    filters: [
      ...map(lenarge.maintenance().getServiceStatusMetaDescriptions(), (desc) => ({ value: desc, text: desc })),
      { value: 'priority', text: 'Prioridade' },
      // { value: 'no-priority', text: 'Sem Prioridade' },
      { value: 'predictable', text: 'Previsão Definida' },
      { value: 'unpredictable', text: 'Sem Previsão' },
      // { value: 213, text: 'Veículos Novos' },
      // { value: 212, text: 'Veículos Vendidos' },
    ],
    // filteredValue: ['Aguard. MO', 'Aguard. Peça', 'Em Execução', 'Liberado', 'priority', 'Abortado', 'predictable', 'unpredictable'],
    filterMultiple: true,
    onFilter: (filterValue: string | number | boolean, service) =>
      service.statusMeta.statusDesc === filterValue ||
      service.classificationCode === filterValue ||
      (filterValue === 'priority' && !!service.priority) ||
      // (filterValue === 'no-priority' && !service.priority) ||
      (filterValue === 'predictable' && !service.unpredictable && !!service.deadlineTime && service.statusMeta.statusDesc !== 'Liberado') ||
      (filterValue === 'unpredictable' && !!service.unpredictable && service.statusMeta.statusDesc !== 'Liberado'),
    render: (field, service) => (
      <ServiceCodeCell
        serviceId={service.serviceId}
        status={service.status}
        rdprServiceCod={service.rdprServiceCod}
        rdprFilialNumber={service.rdprFilialNumber}
        statusMeta={service.statusMeta}
        statusChangeTime={service.statusChangeTime}
        sectorsFinishTime={service.sectorsFinishTime}
        isEmergencyCare={service.emergencyCare}
        isPriority={service.priority}
        isUnpredictable={service.unpredictable}
        deadlineTime={service.deadlineTime}
        classificationCode={service.classificationCode}
      />
    ),
  },
  // {
  //   title: 'Localização',
  //   key: 'lastTrace',
  //   width: 350,
  //   render: (field, service) => (service.vehicleKind === 'truck' ? <LastTruckTraceCell truckId={service.vehicleId} /> : null),
  // },
  ...map(
    {
      nEjD2ixUeUr2m6: 'MECÂNICA',
      '2oGDZmcLNkBkUd': 'ELETRICA',
      '1uhpldR4ZWftxN': 'LUBRIF.',
      OCURKeVwkeBbJX: 'BORRACH.',
      Km6lMJgoUNy1qa: 'PATIO',
      lZ4b0t9DHMoOSy: 'SUSP CAV',
      QcpxzVLCy7GL62: 'FR/SUSP CARRETA',
      '8E3Io7qj5ydz5A': 'REP. CAR.',
    },
    (sectorName, sectorId): ColumnProps<TableDataRow> => ({
      title: sectorName,
      key: `sectors[${sectorId}]`,
      dataIndex: `sectors[${sectorId}]`,
      width: 50,
      filters: map(lenarge.maintenance().getServiceSectorStatusMetaDescriptions(), (desc) => ({ value: desc, text: desc })),
      onFilter: (statusDesc: string | number | boolean, service) => {
        if (!service.statusMeta.sectorsStatusMeta[sectorId]) return false
        return service.statusMeta.sectorsStatusMeta[sectorId].statusDesc === statusDesc
      },
      render: (field, service) => {
        const sectorStatusMeta = service.statusMeta.sectorsStatusMeta[sectorId]
        if (!sectorStatusMeta) return null
        return <ServiceSectorCell sectorStatusMeta={sectorStatusMeta} />
      },
    })
  ),
  {
    title: 'Observações',
    dataIndex: 'description',
    key: 'description',
    width: 270,
    render: (field, service) => <div className="description-cell">{service.description}</div>,
  },
]
