import * as React from 'react'

export function useTimeIntervalRefresh(ms: number = 30 * 1000) {
  const [now, setNow] = React.useState(1)
  React.useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 30 * 1000)
    return () => clearInterval(interval)
  }, [ms])
  return now
}
