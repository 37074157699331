import * as React from 'react'
import { AgentDemandTemplate } from '@lenarge/db-schema'
import { message } from 'antd'
import { trim, map } from 'lodash'

import lenarge from '../../clients/lenarge'
import { IndexedAutoComplete, IndexedAutoCompleteProps } from '../algolia'

import './AgentDemandCreator.scss'

export interface AgentDemandCreatorProps {
  truckId: string
  initialStatus?: 'TO_DO' | 'EXECUTING'
}
export const AgentDemandCreator: React.FC<AgentDemandCreatorProps> = ({ truckId, initialStatus }) => {
  const [creating, setCreating] = React.useState(false)
  const handleCreate = React.useCallback(
    async (value: any) => {
      if (typeof value !== 'string') return
      const template: AgentDemandTemplate = JSON.parse(value)
      console.log('Selected', { template })
      if (creating) return
      if (!template) return
      setCreating(true)
      try {
        if (template.objectOrigin === 'bookmark')
          await lenarge
            .demands()
            .createTruckDemands(truckId, { status: initialStatus || 'TO_DO', placeName: template.placeName, placeId: template.placeId, actionId: template.actionId })
        if (template.objectOrigin === 'logistic_demand_flow' && template.logisticDemandId) {
          const ORACLE_OTM_SHIPMENT_ID = trim(window.prompt('Qual o ID da viagem no Oracle OTM?') || '')
          console.log(ORACLE_OTM_SHIPMENT_ID)
          if (!ORACLE_OTM_SHIPMENT_ID) {
            message.warning('Abortamos a atribuição desta demanda!')
            return
          }
          await lenarge.demands().createTruckDemandsFromLogisticFlow(truckId, {
            status: initialStatus || 'TO_DO',
            logisticDemandId: template.logisticDemandId,
            logisticDemandFlowId: template.objectID,
            customFields: { ORACLE_OTM_SHIPMENT_ID },
          })
        }
        message.success('Demandas atribuídas com sucesso!')
        setCreating(false)
      } catch (error) {
        message.error('Ops! Não foi possível atribuir esta demanda')
        console.error(error)
        setCreating(false)
      }
    },
    [initialStatus, truckId, creating, setCreating]
  )

  const iteratee = React.useCallback<NonNullable<Exclude<IndexedAutoCompleteProps['iteratee'], string>>>(hit => {
    const template: AgentDemandTemplate = hit as any
    // const firstDescription = `${template.actionName} em ${template.placeName}`
    // const stepsDescriptions = map(template.steps, step => ` => ${step.actionName} em ${step.placeName}`)
    const firstDescription = template.placeName
    const stepsDescriptions = map(template.steps, step => ` => ${step.placeName}`)
    return {
      value: JSON.stringify(template),
      text: firstDescription + stepsDescriptions.join(''),
    }
  }, [])

  return (
    <div className="agent-demand-creator">
      <IndexedAutoComplete indexName="agentDemandTemplates" onSelect={handleCreate} iteratee={iteratee} allowClear />
    </div>
  )
}
