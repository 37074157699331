let config: any

const configJson = process.env.REACT_APP_CONFIG_JSON
const useLocalApi = !!process.env.REACT_APP_USE_LOCAL_API

try {
  if (!configJson) throw new Error('Variable REACT_APP_CONFIG_JSON not declared')
  config = JSON.parse(configJson)
  if (useLocalApi) {
    if (!config.lenarge) config.lenarge = {}
    config.lenarge.apiUrl = 'http://localhost:5001/'
  }
} catch (error) {
  console.error('Error parsing config json', error)
  config = {}
}

export default config
