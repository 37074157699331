import * as React from 'react'
import { PlayCircleTwoTone, CheckCircleTwoTone, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Spin, message, Popconfirm } from 'antd'
import { TruckDemand } from '@lenarge/db-schema'

import lenarge, { demands, places } from '../../clients/lenarge'
import { CounterFromNow } from '../moment'

import './TruckDemandWidget.styles.scss'

export const TruckDemandWidget: React.FC<{ demandId: string; demand: TruckDemand }> = ({ demandId, demand }) => {
  const [place, loadingPlace] = places.usePlace(demand.placeId)
  const [action, loadingAction] = demands.useDemandAction(demand.actionId)

  const truckId = demand.truckId
  const status = demand.status
  const time = (() => {
    switch (demand.status) {
      case 'EXECUTING':
        return demand.startTime || demand.reportTime
      case 'FINISHED':
        return demand.finishTime || demand.reportTime
      case 'ABORTED':
        return demand.abortTime || demand.reportTime
    }
    return demand.reportTime
  })()

  const [starting, setStarting] = React.useState(false)
  const handleStartDemandClick = React.useCallback(async () => {
    if (starting) return
    setStarting(true)
    try {
      await lenarge.demands().startTruckDemand(truckId, demandId)
      message.success('Demanda iniciada com sucesso!')
    } catch (error) {
      message.error('Ops! Não foi possível iniciar esta demanda')
      console.error(error)
    }
    setStarting(false)
  }, [demandId, truckId, starting, setStarting])

  const [finishing, setFinishing] = React.useState(false)
  const handleFinishDemandClick = React.useCallback(async () => {
    if (finishing) return
    setFinishing(true)
    try {
      await lenarge.demands().finishTruckDemand(truckId, demandId)
      message.success('Demanda finalizada com sucesso!')
    } catch (error) {
      message.error('Ops! Não foi possível finalizar esta demanda')
      console.error(error)
    }
    setFinishing(false)
  }, [demandId, truckId, finishing, setFinishing])

  const [aborting, setAborting] = React.useState(false)
  const handleAbortDemandClick = React.useCallback(async () => {
    if (aborting) return
    setAborting(true)
    try {
      await lenarge.demands().abortTruckDemand(truckId, demandId)
      message.warning('Demanda abortada!')
    } catch (error) {
      message.error('Ops! Não foi possível abortar esta demanda')
      console.error(error)
    }
    setAborting(false)
  }, [demandId, truckId, aborting, setAborting])

  const loading = loadingPlace || loadingAction

  const actionName = action?.name || demand.actionId
  const placeName = demand.placeName || place?.name || demand.placeId || 'N/A'

  const otmShipmentId = demand.customFields?.ORACLE_OTM_SHIPMENT_ID

  return (
    <div className="truck-demand-widget">
      {loading && (
        <div className="loading">
          <Spin />
        </div>
      )}
      <div className="description">
        {actionName}
        {' em '}
        {placeName}
        <span className="counter">
          {'('}
          <CounterFromNow time={time} />
          {')'}
        </span>
      </div>
      <div className="footer">
        <div className="actions">
          {status === 'TO_DO' && (
            <Popconfirm
              placement="leftBottom"
              title="Tem certeza que deseja INICIAR esta demanda?"
              okText="Sim"
              cancelText="Não"
              onConfirm={handleStartDemandClick}
            >
              {starting ? <LoadingOutlined /> : <PlayCircleTwoTone />}
            </Popconfirm>
          )}
          {status === 'EXECUTING' && (
            <Popconfirm
              placement="leftBottom"
              title="Tem certeza que deseja FINALIZAR esta demanda?"
              okText="Sim"
              cancelText="Não"
              onConfirm={handleFinishDemandClick}
            >
              {finishing ? <LoadingOutlined /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}
            </Popconfirm>
          )}
          {(status === 'TO_DO' || status === 'EXECUTING') && (
            <Popconfirm
              placement="leftBottom"
              title="Tem certeza que deseja ABORTAR esta demanda?"
              okText="Sim"
              cancelText="Não"
              onConfirm={handleAbortDemandClick}
            >
              {aborting ? <LoadingOutlined /> : <CloseCircleOutlined />}
            </Popconfirm>
          )}
        </div>
        <div className="note">{otmShipmentId && ` OTM ID: ${otmShipmentId}`}</div>
      </div>
    </div>
  )
}
