import * as React from 'react'
import { PageHeader, Table } from 'antd'
import { toArray, kebabCase } from 'lodash'
import moment from 'moment'

import './styles.scss'

import lenarge, { vehicles, maintenance, places } from '../../clients/lenarge'
import { useQueryParams, useWindowSize } from '../../utils'

import { columns, TableDataRow } from './table-columns'
import { MaintenanceService } from '@lenarge/db-schema'

export const MaintenanceServicesByVehicleTable: React.FC = () => {
  const { height } = useWindowSize()
  const [queryParams] = useQueryParams()
  // const fleetId = queryParams.get('fleetId') || undefined
  const placeId = queryParams.get('placeId') || undefined

  const [place, loadingPlace] = places.usePlace(placeId)

  const [pendingPlaceServices, loadingPendingPlaceServices] = maintenance.useMaintenanceServicesOfPlace(placeId, {
    status: 'PENDING',
  })

  const [startTime, setStartTime] = React.useState(moment().subtract(2, 'hours').valueOf())
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setStartTime(moment().subtract(2, 'hours').valueOf())
    }, 10 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [])
  const [finishedPlaceServices, loadingFinishedPlaceServices] = maintenance.useMaintenanceServicesOfPlace(placeId, {
    status: 'FINISHED',
    startTime,
  })

  const [trucks, loadingTrucks] = vehicles.useTrucks()
  const [trailers, loadingTrailers] = vehicles.useTrailers()

  // TODO: debounce para diminuir updates
  const tableDataSource: TableDataRow[] = React.useMemo(() => {
    if (!pendingPlaceServices && !finishedPlaceServices) return []
    const rows: { [vehicleId: string]: TableDataRow } = {}
    if (pendingPlaceServices) {
      for (const serviceId in pendingPlaceServices) {
        const service = pendingPlaceServices[serviceId]
        if (isHiddenService(service)) continue
        const statusMeta = lenarge.maintenance().calculateServiceMeta(service)
        const row: TableDataRow = { ...service, serviceId, statusMeta }
        if (service.vehicleKind === 'truck' && trucks && trucks[service.vehicleId]) row.truck = trucks[service.vehicleId]
        if (service.vehicleKind === 'trailer' && trailers && trailers[service.vehicleId]) row.trailer = trailers[service.vehicleId]
        rows[service.vehicleId] = row
      }
    }
    if (finishedPlaceServices) {
      for (const serviceId in finishedPlaceServices) {
        const service = finishedPlaceServices[serviceId]
        if (isHiddenService(service)) continue
        const currentService = rows[service.vehicleId]
        if (currentService) {
          if (currentService.status === 'PENDING') continue
          if (currentService.statusChangeTime > service.statusChangeTime) continue
        }
        const statusMeta = lenarge.maintenance().calculateServiceMeta(service)
        const row: TableDataRow = { ...service, serviceId, statusMeta }
        if (service.vehicleKind === 'truck' && trucks && trucks[service.vehicleId]) row.truck = trucks[service.vehicleId]
        if (service.vehicleKind === 'trailer' && trailers && trailers[service.vehicleId]) row.trailer = trailers[service.vehicleId]
        rows[service.vehicleId] = row
      }
    }
    return toArray(rows)
    function isHiddenService(s: MaintenanceService & { classificationCode?: number }): boolean {
      const finishTime = s.finishTime || s.sectorsFinishTime
      const ago1hour = Date.now() - 60 * 60 * 1000
      if (finishTime && finishTime < ago1hour) return true
      if (!s.classificationCode) return false
      return (
        s.classificationCode === 213 ||
        s.classificationCode === 212 ||
        s.classificationCode === 214 ||
        s.classificationCode === 175 ||
        !!s.emergencyCare
      )
    }
  }, [pendingPlaceServices, finishedPlaceServices, trucks, trailers])

  const calculateRowClassName = React.useCallback((row: TableDataRow) => kebabCase(row.statusMeta.statusDesc), [])

  const loading = loadingPlace || loadingPendingPlaceServices || loadingFinishedPlaceServices || loadingTrucks || loadingTrailers

  return (
    <div className="maintenance-services-vehicles-table-screen">
      <PageHeader
        title={`Serviços de Manutenção${place ? ` em ${place.name}` : ''}`}
        subTitle={`Monitoramento de execução e liberação para Cavalos e Carretas - ${tableDataSource.length} OS`}
      />
      <Table
        className="maintenance-services-table"
        dataSource={tableDataSource}
        columns={columns}
        rowKey="serviceId"
        loading={loading}
        size="small"
        pagination={false}
        rowClassName={calculateRowClassName}
        scroll={{
          x: 1600,
          y: height ? height - 63 - 37 - 20 : undefined,
        }}
      />
    </div>
  )
}
