import * as React from 'react'
import { MaintenanceWorker } from '@lenarge/db-schema'
import { last } from 'lodash'
import { DeleteOutlined, LoadingOutlined, ProjectOutlined, QuestionCircleOutlined, SolutionOutlined } from '@ant-design/icons'
import { Card, Popconfirm, Tag, message } from 'antd'

import lenarge, { maintenance } from '../../clients/lenarge'
import { useQueryParams } from '../../utils'
import { CounterFromNow } from '../moment'
import { useServiceDrawer } from './ServiceDrawer'

import './WorkerCard.styles.scss'
import { useWorkerOverviewDrawer } from './WorkerOverviewDrawer'

export interface WorkerCardProps {
  workerId: string
  worker: MaintenanceWorker
  hideStatus?: boolean
  hideSector?: string
  style?: React.CSSProperties | undefined
  size?: 'small' | 'default'
  onChangeSector?: (workerId: string) => any
}
export const WorkerCard: React.FC<WorkerCardProps> = (props) => {
  const [queryParams] = useQueryParams()
  const isAdmin = queryParams.get('admin') === 'islene'

  const [sector, loadingSector] = maintenance.useMaintenanceSector(props.hideSector ? undefined : props.worker.sectorId)
  const statusMeta = lenarge.maintenance().getWorkerStatusMeta(props.worker.status)
  const lastName = React.useMemo(() => last(props.worker.surname.split(' ')), [props.worker.surname])

  const { workerId, worker, onChangeSector } = props
  const { workingServiceId } = worker

  const { showServiceDrawer } = useServiceDrawer()
  const handleShowServiceDrawer = React.useCallback(() => {
    if (!workingServiceId) return
    showServiceDrawer(workingServiceId)
  }, [workingServiceId, showServiceDrawer])

  const { showWorkerOverviewDrawer } = useWorkerOverviewDrawer()
  const handleShowWorkerOverviewDrawer = React.useCallback(() => {
    showWorkerOverviewDrawer(workerId)
  }, [workerId, showWorkerOverviewDrawer])

  const [deactivating, setDeactivating] = React.useState(false)
  const handleDeactivateClick = React.useCallback(async () => {
    if (!isAdmin) return
    if (deactivating) return
    setDeactivating(true)
    try {
      await lenarge.maintenance().deactivateMaintenanceWorker(workerId)
    } catch (error) {
      message.error('Ops, não foi possível concluir a desativação!')
      console.error('Error deactivating maintenance worker', error)
    }
    setDeactivating(false)
  }, [workerId, isAdmin, deactivating, setDeactivating])

  const handleUpdateSectorClick = React.useCallback(() => {
    if (!onChangeSector) return
    onChangeSector(workerId)
  }, [workerId, onChangeSector])

  return (
    <Card className="worker-card" bordered={false} style={props.style} size={props.size} loading={loadingSector}>
      <div className="first-name"> {props.worker.firstName} </div>
      <div className="last-name">{lastName}</div>
      <div className="counter">
        <CounterFromNow time={props.worker.statusChangeTime} />
      </div>
      <div className="footer">
        <div className="tags">
          {!props.hideStatus && (
            <Tag className="status-tag" color={statusMeta.color}>
              {statusMeta.label}
            </Tag>
          )}
          {sector && (
            <Tag className="sector-tag" onClick={isAdmin ? handleUpdateSectorClick : undefined}>
              {sector.name}
            </Tag>
          )}
        </div>
        <div className="actions">
          {isAdmin && !deactivating && (
            <Popconfirm
              title="Tem certeza disto？"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={handleDeactivateClick}
              okText="Sim"
              cancelText="Cancelar"
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
          {isAdmin && deactivating && <LoadingOutlined />}
          {props.worker.workingServiceId && <ProjectOutlined onClick={handleShowServiceDrawer} />}
          <SolutionOutlined onClick={handleShowWorkerOverviewDrawer} />
        </div>
      </div>
    </Card>
  )
}
