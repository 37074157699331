import * as React from 'react'
import { CopyOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { PageHeader, Table, Spin, message, Button, Input } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { ButtonProps } from 'antd/lib/button'
import { DrawerProps } from 'antd/lib/drawer'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { flatMap, map, sortBy, kebabCase, maxBy } from 'lodash'
import { LogisticDemandFeatures, LogisticDemandStatus, LogisticDemandFlow, LogisticDemandFlowStep } from '@lenarge/db-schema'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import './styles.scss'

import lenarge, { places, demands } from '../../clients/lenarge'
import { useWindowSize } from '../../utils'
import { DateAndHour } from '../../components/moment'
import { ResponsibleDrawer } from '../../components/drawer'
import { IndexedAutoComplete } from '../../components/algolia'

interface TableDataRow {
  key: string
  logisticDemandId: string
  status: LogisticDemandStatus
  fleetId?: string
  reportTime: number
  startTime?: number
  finishTime?: number
  abortTime?: number

  features?: LogisticDemandFeatures

  flowId?: string
  flow?: LogisticDemandFlow
}
const columns: ColumnProps<TableDataRow>[] = [
  {
    title: 'Id da Demanda',
    dataIndex: 'logisticDemandId',
    key: 'logisticDemandId',
    width: 220,
    render: (field, row) => <DemandIdCell row={row} />,
  },
  {
    title: 'Criado em',
    dataIndex: 'reportTime',
    key: 'reportTime',
    sorter: (a, b) => a.reportTime - b.reportTime,
    defaultSortOrder: 'ascend',
    width: 110,
    render: (field, row) => (
      <div>
        <DateAndHour time={row.reportTime} />
      </div>
    ),
  },
  {
    title: 'Id do Fluxo',
    dataIndex: 'flowId',
    key: 'flowId',
    width: 220,
    render: (field, row) => <FlowIdCell row={row} />,
  },
  {
    title: 'Fluxo',
    dataIndex: 'flow',
    key: 'flow',
    render: (field, row) => <FlowCell row={row} />,
  },
]

export const LogisticDemands: React.FC = () => {
  const { height, width } = useWindowSize()

  const [logisticDemands, loadingLogisticDemands] = demands.useLogisticDemands()

  const tableDataSource: TableDataRow[] = React.useMemo(() => {
    if (!logisticDemands) return []
    return flatMap(logisticDemands, (demand, logisticDemandId) => {
      const row: TableDataRow = {
        key: logisticDemandId,
        logisticDemandId,
        status: demand.status,
        reportTime: demand.reportTime,
      }
      if (demand.startTime) row.startTime = demand.startTime
      if (demand.finishTime) row.finishTime = demand.finishTime
      if (demand.abortTime) row.abortTime = demand.abortTime
      if (demand.features) row.features = demand.features
      if (demand.fleetId) row.fleetId = demand.fleetId
      if (!demand.flows) return [row]
      return map(demand.flows, (flow, flowId) => ({ ...row, key: `${logisticDemandId}_${flowId}`, flow, flowId }))
    })
  }, [logisticDemands])

  const [createFlowDrawerVisible, setCreateFlowDrawerVisible] = React.useState(false)
  const handleCreateFlowClick = React.useCallback(() => setCreateFlowDrawerVisible(true), [setCreateFlowDrawerVisible])
  const handleCloseCreateFlowDrawer = React.useCallback(() => setCreateFlowDrawerVisible(false), [setCreateFlowDrawerVisible])

  const loading = loadingLogisticDemands

  if (loading)
    return (
      <div className="logistic-demands-screen-loading">
        <Spin />
      </div>
    )

  return (
    <div className="logistic-demands-screen">
      <PageHeader
        title="Demandas Logísticas"
        subTitle="Gestão de Demandas"
        extra={[
          <CreatePlaceButton key="place">Cria Local</CreatePlaceButton>,
          <CreateActionButton key="action">Cria Ação</CreateActionButton>,
          <Button key="flow" type="primary" onClick={handleCreateFlowClick}>
            Cria Fluxo
          </Button>,
        ]}
      />
      <CreateFlowDrawer visible={createFlowDrawerVisible} onClose={handleCloseCreateFlowDrawer} />
      <Table
        className="logistic-demands-table"
        dataSource={tableDataSource}
        columns={columns}
        loading={loading}
        size="small"
        pagination={false}
        scroll={{
          x: width ? width - 170 : undefined,
          y: height ? height - 63 - 37 : undefined,
        }}
      />
    </div>
  )
}

const DemandIdCell: React.FC<{ row: TableDataRow }> = ({ row }) => {
  const handleCopy = React.useCallback(() => message.success('Copiado com sucesso'), [])
  return (
    <div>
      <CopyToClipboard onCopy={handleCopy} text={row.logisticDemandId}>
        <span>
          {row.logisticDemandId} <CopyOutlined />
        </span>
      </CopyToClipboard>
    </div>
  )
}

const CreateFlowDrawer: React.FC<Omit<DrawerProps, 'className' | 'title'>> = (props) => {
  return (
    <ResponsibleDrawer {...props} className="create-flow-drawer" title="Cria Fluxo de Demanda">
      <CreateFlowForm onSubmit={props.onClose} />
    </ResponsibleDrawer>
  )
}

const CreateFlowFormWrapper: React.FC<FormComponentProps<{ logisticDemandId: string }>> = (props) => {
  const { getFieldDecorator, validateFields } = props.form

  const [creatingFlow, setCreatingFlow] = React.useState(false)
  const [stepsOrder, setStepsOrder] = React.useState<{ [key: string]: number }>({})
  const [stepsActionId, setStepsActionId] = React.useState<{ [key: string]: string }>({})
  const handlerSetStepActionId = React.useCallback(
    (stepId: string) => (value?: string) =>
      setStepsActionId((state) => {
        const nextState = { ...state }
        if (typeof value === 'string' && value !== '') nextState[stepId] = value
        else delete nextState[stepId]
        return nextState
      }),
    [setStepsActionId]
  )
  const [stepsPlaceId, setStepsPlaceId] = React.useState<{ [key: string]: string }>({})
  const handlerSetStepPlaceId = React.useCallback(
    (stepId: string) => (value?: string) =>
      setStepsPlaceId((state) => {
        const nextState = { ...state }
        if (typeof value === 'string' && value !== '') nextState[stepId] = value
        else delete nextState[stepId]
        return nextState
      }),
    [setStepsPlaceId]
  )
  const [stepsPlaceName, setStepsPlaceName] = React.useState<{ [key: string]: string }>({})
  const handlerSetStepPlaceName = React.useCallback(
    (stepId: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setStepsPlaceName((state) => {
        const nextState = { ...state }
        const value = event.target.value
        if (value !== '') nextState[stepId] = value
        else delete nextState[stepId]
        return nextState
      }),
    [setStepsPlaceName]
  )

  const handleFormSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (creatingFlow) return
      validateFields(async (err, values) => {
        console.log('Received values of form: ', values)
        const { logisticDemandId } = values
        if (err) {
          console.error(err)
          return message.error('Ops, dados inválidos!')
        }
        setCreatingFlow(true)
        try {
          const steps: LogisticDemandFlowStep[] = []
          for (const stepId in stepsOrder) {
            const order = stepsOrder[stepId]
            const actionId = stepsActionId[stepId]
            const placeId = stepsPlaceId[stepId]
            const placeName = stepsPlaceName[stepId]
            if (!actionId) return message.error('A ação de todos os passos deve ser preenchida')
            if (!placeId && !placeName) return message.error('O local de todos os passos deve ser preenchido')
            steps.push({ agentType: 'truck', actionId, placeId, placeName, order, customFields: { ORACLE_OTM_SHIPMENT_ID: true } })
          }
          if (steps.length === 0) return message.error('Um fluxo deve conter no mínimo um passo')
          await lenarge.demands().createLogisticDemandFlow(logisticDemandId, { steps })
          setCreatingFlow(false)
        } catch (error) {
          console.error(error)
          message.error('Ops, não foi possível criar!')
          setCreatingFlow(false)
        }
      })
    },
    [validateFields, creatingFlow, setCreatingFlow, stepsOrder, stepsActionId, stepsPlaceId, stepsPlaceName]
  )

  const handleAddStepField = React.useCallback(() => {
    const lastStep = maxBy(
      map(stepsOrder, (order, stepId) => ({ order, stepId })),
      'order'
    )
    const maxOrder = lastStep && lastStep.order !== undefined ? lastStep.order : 0
    const stepOrder = maxOrder + 1
    const stepId = lenarge._clients().rtdb.key()
    setStepsActionId((state) => ({ ...state, [stepId]: '' }))
    setStepsPlaceId((state) => ({ ...state, [stepId]: '' }))
    setStepsPlaceName((state) => ({ ...state, [stepId]: '' }))
    setStepsOrder((state) => ({ ...state, [stepId]: stepOrder }))
  }, [stepsOrder, setStepsOrder, setStepsActionId, setStepsPlaceId, setStepsPlaceName])
  const handlerRemoveStepField = React.useCallback(
    (stepId: string) => () => {
      setStepsOrder((state) => {
        const nextState = { ...state }
        delete nextState[stepId]
        return nextState
      })
      setStepsActionId((state) => {
        const nextState = { ...state }
        delete nextState[stepId]
        return nextState
      })
      setStepsPlaceId((state) => {
        const nextState = { ...state }
        delete nextState[stepId]
        return nextState
      })
      setStepsPlaceName((state) => {
        const nextState = { ...state }
        delete nextState[stepId]
        return nextState
      })
    },
    [setStepsOrder, setStepsActionId, setStepsPlaceId, setStepsPlaceName]
  )

  const stepKeys = React.useMemo(
    () =>
      map(
        sortBy(
          map(stepsOrder, (order, stepId) => ({ order, stepId })),
          'order',
          'asc'
        ),
        (step) => step.stepId
      ),
    [stepsOrder]
  )

  return (
    <Form layout="vertical" onSubmit={handleFormSubmit}>
      <Form.Item label="Id da Demanda">
        {getFieldDecorator('logisticDemandId', {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, whitespace: true, message: 'Por favor, preencha aqui com o Id da Demanda já existente' }],
        })(<Input placeholder="Preencher com Id" />)}
      </Form.Item>
      {map(stepKeys, (key, index) => (
        <Form.Item label={index === 0 ? 'Passos' : ''} required={false} key={key}>
          <Input placeholder="Preencher com o Nome" style={{ marginRight: 8 }} value={stepsPlaceName[key]} onChange={handlerSetStepPlaceName(key)} allowClear />
          <IndexedAutoComplete
            indexName="places"
            placeholder="Busque por um local"
            style={{ marginRight: 8 }}
            value={stepsPlaceId[key]}
            onChange={handlerSetStepPlaceId(key)}
            allowClear
          />
          <IndexedAutoComplete
            indexName="demandActions"
            placeholder="Busque por uma ação"
            style={{ marginRight: 8 }}
            value={stepsActionId[key]}
            onChange={handlerSetStepActionId(key)}
            allowClear
          />
          {stepKeys.length > 1 ? <MinusCircleOutlined className="dynamic-delete-button" onClick={handlerRemoveStepField(key)} /> : null}
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={handleAddStepField} style={{ width: '60%' }}>
          <PlusOutlined /> Adciciona mais um Passo
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Criar Fluxo de Demanda
        </Button>
      </Form.Item>
    </Form>
  )
}
const CreateFlowForm = Form.create<FormComponentProps<{ logisticDemandId: string }> & { onSubmit?: (e: any) => void }>({
  name: 'create-flow-form',
})(CreateFlowFormWrapper)

const FlowIdCell: React.FC<{ row: TableDataRow }> = ({ row }) => {
  const handleCopy = React.useCallback(() => message.success('Copiado com sucesso'), [])
  if (!row.flowId) return null
  return (
    <div>
      <CopyToClipboard onCopy={handleCopy} text={row.flowId}>
        <span>
          {row.flowId} <CopyOutlined />
        </span>
      </CopyToClipboard>
    </div>
  )
}

const FlowCell: React.FC<{ row: TableDataRow }> = ({ row }) => {
  const flowSteps = row.flow?.steps
  const steps = React.useMemo(
    () =>
      sortBy(
        map(flowSteps, (step, stepId) => ({ ...step, stepId })),
        'order',
        'asc'
      ),
    [flowSteps]
  )
  if (!row.flow) return null
  return (
    <div className="flow-cell">
      {map(steps, (step) => (
        <FlowStep key={step.stepId} step={step} />
      ))}
    </div>
  )
}

const FlowStep: React.FC<{ step: LogisticDemandFlowStep }> = ({ step }) => {
  const [place] = places.usePlace(step.placeId)
  const [action] = demands.useDemandAction(step.actionId)
  return (
    <span className="flow-step">
      {step.order}
      {': '}
      {action?.name || step.actionId}
      {' em '}
      {step.placeName || place?.name || step.placeId || 'N/A'}
    </span>
  )
}

const CreatePlaceButton: React.FC<ButtonProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const handleCreatePlaceClick = React.useCallback(async () => {
    if (loading) return
    try {
      const placeId = window.prompt('Qual o id proposto para o local?')
      if (!placeId) {
        message.error('Não é possível criar um local sem um id')
        return
      }
      const name = window.prompt('Qual o nome do local?')
      if (!name) {
        message.error('Não é possível criar um local sem nome')
        return
      }
      setLoading(true)
      try {
        await lenarge.places().createOrUpdatePlace(kebabCase(placeId), { name })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw error
      }
    } catch (error) {
      message.error('Erro ao tentar criar um local')
      console.error(error)
    }
  }, [loading, setLoading])
  return <Button {...props} onClick={handleCreatePlaceClick} />
}

const CreateActionButton: React.FC<ButtonProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const handleCreateActionClick = React.useCallback(async () => {
    if (loading) return
    try {
      const actionId = window.prompt('Qual o id proposto para a ação de demanda?')
      if (!actionId) {
        message.error('Não é possível criar uma ação de demanda sem um id')
        return
      }
      const name = window.prompt('Qual o nome da ação de demanda?')
      if (!name) {
        message.error('Não é possível criar uma ação de demanda sem nome')
        return
      }
      setLoading(true)
      try {
        await lenarge.demands().createOrUpdateDemandAction(kebabCase(actionId), { name })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw error
      }
    } catch (error) {
      message.error('Erro ao tentar criar uma ação de demanda')
      console.error(error)
    }
  }, [loading, setLoading])
  return <Button {...props} onClick={handleCreateActionClick} />
}
