import * as React from 'react'
import { Spin } from 'antd'
import { map } from 'lodash'

import { demands } from '../../clients/lenarge'
import { TruckDemandWidget, AgentDemandCreator } from '../../components/demand'

export const TruckDemandsToDo: React.FC<{ truckId: string }> = ({ truckId }) => {
  const [truckDemands, loading] = demands.useTruckDemands(truckId, { status: 'TO_DO' })
  if (loading)
    return (
      <div>
        <Spin />
      </div>
    )
  return (
    <div className="demands-to-do-cell">
      {map(truckDemands, (demand, demandId) => (
        <TruckDemandWidget key={demandId} demandId={demandId} demand={demand} />
      ))}
      <AgentDemandCreator truckId={truckId} initialStatus="TO_DO" />
    </div>
  )
}
