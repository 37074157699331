import * as React from 'react'
import { get } from 'lodash'
import { Hit } from 'react-instantsearch-core'
import { InstantSearch, connectAutoComplete, Configure } from 'react-instantsearch-dom'
import { AutoComplete } from 'antd'
import { DataSourceItemType } from 'antd/lib/auto-complete'

import lenarge from '../../clients/lenarge'

export interface IndexedAutoCompleteProps {
  indexName: string
  value?: string
  onSelect?: (value: string | undefined, option: any) => any
  onChange?: (value?: string) => any

  style?: React.CSSProperties
  placeholder?: string
  allowClear?: boolean

  hitsPerPage?: number
  iteratee?: string | (<T = any>(hit: Hit<T>) => DataSourceItemType)
}
export const IndexedAutoComplete: React.FC<IndexedAutoCompleteProps> = props => {
  return (
    <InstantSearch searchClient={lenarge._clients().algolia} indexName={props.indexName}>
      <AutoCompleteField
        value={props.value}
        onSelect={props.onSelect}
        onChange={props.onChange}
        iteratee={props.iteratee}
        style={props.style}
        placeholder={props.placeholder}
        allowClear={props.allowClear}
      />
      <Configure hitsPerPage={props.hitsPerPage} />
    </InstantSearch>
  )
}

interface AutoCompleteWrapperProps<T = any> {
  value?: string
  onChange?: (value?: string) => void
  onSelect?: (value: string | undefined, option: any) => void
  hits: Array<Hit<T>>
  currentRefinement: string
  refine(value?: string): void
  iteratee?: string | (<T = any>(hit: Hit<T>) => DataSourceItemType)
  allowClear?: boolean
  style?: React.CSSProperties
  placeholder?: string
}
const AutoCompleteFieldWrapper: React.FC<AutoCompleteWrapperProps> = props => {
  const { refine } = props
  const handleSearch = React.useCallback((searchText: string) => refine(searchText), [refine])

  let iterateeFn: <T = any>(hit: Hit<T>) => DataSourceItemType = hit => hit.objectID
  if (props.iteratee) {
    const iteratee = props.iteratee
    if (typeof iteratee === 'string') iterateeFn = hit => get(hit, iteratee)
    else iterateeFn = iteratee
  }

  const [fieldValue, setFieldValue] = React.useState<string>('')

  const dataSource = props.hits.map(iterateeFn)

  return (
    <AutoComplete
      dataSource={dataSource}
      value={props.value || fieldValue}
      onSelect={props.onSelect}
      onChange={props.onChange || setFieldValue}
      onSearch={handleSearch}
      allowClear={props.allowClear}
      style={props.style}
      placeholder={props.placeholder}
    />
  )
}
const AutoCompleteField = connectAutoComplete(AutoCompleteFieldWrapper)
