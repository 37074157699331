import * as React from 'react'
import { useLocation, useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { debounce } from 'lodash'

export { useParams, useHistory, useRouteMatch, useLocation }

export type SetParamsState = (nextParams: URLSearchParams) => URLSearchParams | undefined | void | any
export function useQueryParams(): [URLSearchParams, (setParamsState: SetParamsState) => void] {
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)
  const setQueryParams = React.useCallback(
    (setParamsState: SetParamsState) => {
      const paramsToMutate = new URLSearchParams(history.location.search)
      const changedParams = setParamsState(paramsToMutate)
      const nextParams = changedParams instanceof URLSearchParams ? changedParams : paramsToMutate
      history.push({ pathname: history.location.pathname, search: nextParams.toString() })
    },
    [history]
  )
  return [queryParams, setQueryParams]
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(getSize)
  React.useEffect(() => {
    const handleResize = debounce(() => setWindowSize(getSize()), 200)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
function getSize() {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

export interface BreakpointStates<T> {
  xxl?: T
  xl?: T
  lg?: T
  md?: T
  sm?: T
  xs?: T
}
export function useBreakpointBasedState<T>(breakpointStates: BreakpointStates<T>): T | undefined {
  const { width } = useWindowSize()
  if (!width) return undefined
  if (width >= 1600 && breakpointStates.xxl) return breakpointStates.xxl
  if (width >= 1200 && breakpointStates.xl) return breakpointStates.xl
  if (width >= 992 && breakpointStates.lg) return breakpointStates.lg
  if (width >= 768 && breakpointStates.md) return breakpointStates.md
  if (width >= 576 && breakpointStates.sm) return breakpointStates.sm
  if (breakpointStates.xs) return breakpointStates.xs
  if (breakpointStates.sm) return breakpointStates.sm
  if (breakpointStates.md) return breakpointStates.md
  if (breakpointStates.lg) return breakpointStates.lg
  if (breakpointStates.xl) return breakpointStates.xl
  if (breakpointStates.xxl) return breakpointStates.xxl
  return undefined
}

export function useChangedFlag(deps: React.DependencyList = [], ms: number = 2.5 * 1000): boolean {
  const [changedFlag, setChangedFlag] = React.useState(false)
  React.useEffect(() => {
    setChangedFlag(true)
    const timeoutId = setTimeout(() => setChangedFlag(false), ms)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChangedFlag, ms, ...deps])
  return changedFlag
}

export function toChangedClassName(active?: boolean) {
  if (active) return 'data-changed'
  return ''
}

export function useChangedClassName(deps: React.DependencyList = [], ms: number = 2.5 * 1000): string {
  return toChangedClassName(useChangedFlag(deps, ms))
}
