import * as React from 'react'
import moment from './moment'
import { padStart } from 'lodash'
import { DefaultMomentProps } from './types'
import { useTimeIntervalRefresh } from './time-interval-refresh-hook'

export interface CounterFromNowProps extends DefaultMomentProps {
  asWeeks?: boolean
  hideHours?: boolean
  withSeconds?: boolean
}
export const CounterFromNow: React.FC<CounterFromNowProps> = ({ time, asWeeks, hideHours, withSeconds }) => {
  const refreshTicker = useTimeIntervalRefresh()

  const counter = React.useMemo(() => {
    if (!time) return null
    if (!refreshTicker) return null
    const diff = moment().diff(time)
    const duration = moment.duration(diff < 0 ? diff * -1 : diff)
    const counterParts: string[] = []

    const signal = diff < 0 ? '-' : ''

    if (asWeeks) {
      if (duration.weeks()) counterParts.push(duration.weeks() + 's')
    } else {
      if (duration.years()) counterParts.push(duration.years() + 'a')
      if (duration.months()) counterParts.push(duration.months() + 'm')
      if (duration.days()) counterParts.push(duration.days() + 'd')
    }

    if (!hideHours) {
      let hours: string = ''
      if (counterParts.length > 0 || duration.hours()) hours += padStart(duration.hours().toString(), 2, '0') + 'h'
      hours += padStart(duration.minutes().toString(), 2, '0') + 'm'
      if (withSeconds) hours += padStart(duration.seconds().toString(), 2, '0') + 's'
      counterParts.push(hours)
    }

    return signal + counterParts.join(' ')
  }, [time, asWeeks, hideHours, withSeconds, refreshTicker])

  return <>{counter}</>
}
