import * as React from 'react'
import { Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { useBreakpointBasedState } from '../../utils'

export const ResponsibleDrawer: React.FC<DrawerProps> = props => {
  const width = useBreakpointBasedState({
    xs: 256,
    sm: 370,
    lg: 470,
  })

  return <Drawer width={width} {...props} />
}
