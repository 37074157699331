import * as React from 'react'
import { ApartmentOutlined } from '@ant-design/icons'
import { PageHeader, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { map } from 'lodash'

import './styles.scss'

import { vehicles } from '../../clients/lenarge'
import { useQueryParams, useWindowSize } from '../../utils'
import { LastTruckTraceCell } from '../../components/vehicle'
import { ActiveTruckDemandCell } from './ActiveTruckDemandCell'
import { TruckDemandsToDo } from './TruckDemandsToDoCell'

interface TableDataRow {
  truckId: string
  licensePlate: string
}
const columns: ColumnProps<TableDataRow>[] = [
  {
    title: <ApartmentOutlined style={{ marginLeft: 4 }} />,
    dataIndex: 'group',
    key: 'group',
    width: 40,
    render: (/* field, truck */) => (
      <div className="group-cell">
        <p>RODOV</p>
      </div>
    ),
  },
  {
    title: 'Placa',
    dataIndex: 'licensePlate',
    key: 'licensePlate',
    sorter: (a, b) => a.licensePlate.localeCompare(b.licensePlate),
    defaultSortOrder: 'ascend',
    width: 110,
    render: (field, truck) => (
      <div className="truck-cell">
        <p className="license-plate">{truck.licensePlate}</p>
        <p className="description">FH460 2011/2011</p>
      </div>
    ),
  },
  // {
  //   title: 'Semi-reboque',
  //   dataIndex: 'trailers',
  //   key: 'trailers',
  //   width: 150,
  //   render: (/* field, truck */) => (
  //     <div className="trailers-cell">
  //       <p className="kind">VANDERLEIA</p>
  //       <p className="license-plate">HFB1566</p>
  //     </div>
  //   ),
  // },
  // {
  //   title: 'Motorista',
  //   dataIndex: 'driver',
  //   key: 'driver',
  //   width: 150,
  //   render: (/* field, truck */) => (
  //     <div className="driver-cell">
  //       <p className="first-name">ADEIR ANTONIO</p>
  //       <p className="surname">DOS ALÍVIOS</p>
  //     </div>
  //   ),
  // },
  {
    title: 'Localização',
    key: 'lastTrace',
    render: (field, truck) => <LastTruckTraceCell truckId={truck.truckId} />,
    width: 350,
  },
  {
    title: 'Demanda em Atuação',
    key: 'activeTruckDemand',
    render: (field, truck) => <ActiveTruckDemandCell truckId={truck.truckId} />,
    width: 300,
  },
  {
    title: 'Próximas Demandas',
    key: 'demandsToDo',
    render: (field, truck) => <TruckDemandsToDo truckId={truck.truckId} />,
  },
]

export const TrucksDemandFlow: React.FC = () => {
  const { height, width } = useWindowSize()
  const [queryParams] = useQueryParams()
  const fleetId = queryParams.get('fleetId') || undefined

  const [trucks, loadingTrucks] = vehicles.useTrucks({ fleetId })

  const tableDataSource: TableDataRow[] = React.useMemo(() => {
    if (!trucks) return []
    return map(trucks, (truck, truckId) => ({ ...truck, truckId }))
  }, [trucks])

  const loading = loadingTrucks

  return (
    <div className="trucks-demand-flow-screen">
      <PageHeader title="Veículos" subTitle="Fluxo de Demandas" />
      <Table
        className="trucks-demand-flow-table"
        dataSource={tableDataSource}
        columns={columns}
        rowKey="truckId"
        loading={loading}
        size="small"
        pagination={false}
        scroll={{
          x: width ? width - 170 : undefined,
          y: height ? height - 63 - 37 : undefined,
        }}
      />
    </div>
  )
}
