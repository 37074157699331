import * as React from 'react'
import Lenarge from '@lenarge/api'
import config from '../config'
import { useSignIn, useAuthPersonId } from '@lenarge/api/react'
export * from '@lenarge/api/react'
const lenarge = Lenarge.initialize(config)
export default lenarge

const handleSignInError = (emailError?: string, passwordError?: string) => console.log('Error signing in', { emailError, passwordError })
export function useSandboxAuth(): [string | undefined, boolean] {
  const [authPersonId, authLoading] = useAuthPersonId()
  const { call: signIn, loading: signInLoading } = useSignIn(handleSignInError)
  React.useEffect(() => {
    if (authPersonId) return
    if (authLoading) return
    console.log('Signing in...')
    const tempEmailAuth = 'lenarge-dev@lenarge.com.br'
    signIn(tempEmailAuth, tempEmailAuth)
  }, [authPersonId, authLoading, signIn, signInLoading])

  const loading = authLoading || signInLoading || authPersonId === undefined
  return [authPersonId, loading]
}
