import Moment from 'react-moment'
import moment from 'moment'
import 'moment/locale/pt-br'

export * from 'react-moment'

moment.locale('pt-br')
Moment.globalMoment = moment
Moment.globalLocale = 'pt-br'
Moment.startPooledTimer()

export { Moment }
export default moment
