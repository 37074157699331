import * as React from 'react'
import { TeamOutlined, ToolOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { ServiceSectorStatusMeta } from '@lenarge/api/lib/modules/maintenance/rtdb'

import './ServiceSectorCell.scss'

export const ServiceSectorCell: React.FC<{ sectorStatusMeta: ServiceSectorStatusMeta }> = ({ sectorStatusMeta }) => {
  return (
    <div className="service-sector-cell">
      <span className="procedures-count">
        <ToolOutlined />
        {sectorStatusMeta.countProcedures - sectorStatusMeta.countPendingProcedures}
        {'/'}
        {sectorStatusMeta.countProcedures}
      </span>
      <span className="workers-count">
        <TeamOutlined />
        {sectorStatusMeta.countWorkers}
      </span>
      <span className="status-tag">
        <Tag color={sectorStatusMeta.statusColor.tagColorName}>{sectorStatusMeta.statusDesc}</Tag>
      </span>
    </div>
  )
}
