import * as React from 'react'
import { Tabs, Row, Col, Badge, Modal, Select, message, Spin } from 'antd'
import { map, size, keys } from 'lodash'
import { MaintenanceWorkerStatus, MaintenanceWorker } from '@lenarge/db-schema'

import lenarge, { maintenance } from '../../clients/lenarge'
import { useParams, useQueryParams } from '../../utils'
import { WorkerCard } from '../../components/maintenance'

import './styles.scss'
import { ExclamationCircleOutlined } from '@ant-design/icons'

type TabKey = MaintenanceWorkerStatus | '_ALL'
interface TabSettings {
  title: string
  showBadge?: boolean
  badgeStyle?: React.CSSProperties
}
const tabsSettings: { [key: string]: TabSettings } = {
  _ALL: { title: 'Todos' },
  WORKING: { title: 'Executando', showBadge: true },
  IDLE: { title: 'Disponíveis', showBadge: true },
  UNAVAILABLE: { title: 'Indisponíveis', showBadge: true },
}
const tabsKeys = keys(tabsSettings)
function getTabKey(key?: string | null): TabKey {
  if (key === '_ALL') return key
  if (key === 'WORKING') return key
  if (key === 'IDLE') return key
  if (key === 'UNAVAILABLE') return key
  return '_ALL'
}

export const WorkersOfPlaceByStatus: React.FC = () => {
  const { placeId } = useParams<{ placeId: string }>()
  const [queryParams, setQueryParams] = useQueryParams()
  const activeStatusTab = getTabKey(queryParams.get('status'))

  const changeActiveStatusTab = React.useCallback(
    (key: string) => {
      const nextStatusTab = getTabKey(key)
      setQueryParams((nextParams) => nextParams.set('status', nextStatusTab))
    },
    [setQueryParams]
  )

  const [workers, loadingWorkers] = maintenance.useMaintenanceWorkersOfPlace(placeId)
  const workersOrderedByName = maintenance.useOrderMaintenanceWorkersByName(workers)
  const workersByStatus = maintenance.useGroupMaintenanceWorkersByStatus(workers) || {}

  const tabCounters = React.useMemo(() => {
    const _tabCounters: { [tabKey: string]: number } = {}
    let allCounter = 0
    for (const status in workersByStatus) {
      const statusCounter = size(workersByStatus[status])
      _tabCounters[status] = statusCounter
      allCounter += statusCounter
    }
    _tabCounters['_ALL'] = allCounter
    return _tabCounters
  }, [workersByStatus])

  const [sectors, loadingSectors] = maintenance.useMaintenanceSectors()
  const handleOpenSectorChangeForm = React.useCallback(
    (workerId: string) => {
      const sectorIdRef = { current: '' }
      const selectSectorId = (nextSectorId: string) => {
        sectorIdRef.current = nextSectorId
      }
      const changeSector = async () => {
        const sectorId = sectorIdRef.current
        if (!sectorId) {
          message.error('Operação cancelada: nenhum setor foi selecionado')
          return
        }
        try {
          await lenarge.maintenance().updateMaintenanceWorkerSector(workerId, sectorId)
          message.success('Alteração efetuada com sucesso!')
        } catch (error) {
          message.error('Ops, não foi possível efetuar esta alteração!')
          console.error('Error updating maintenance worker sector =>', error)
        }
      }
      Modal.confirm({
        title: 'Para qual setor deseja transferir este colaborador?',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <Select placeholder="Selecione um setor" style={{ width: 250 }} onChange={selectSectorId}>
              {map(sectors, (sector, sectorId) => (
                <Select.Option key={sectorId} value={sectorId}>
                  {sector.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        ),
        okText: 'Alterar',
        cancelText: 'Cancelar',
        onOk: changeSector,
      })
    },
    [sectors]
  )

  const loading = loadingWorkers || loadingSectors
  if (loading)
    return (
      <div className="workers-by-status-loading">
        <Spin />
      </div>
    )

  return (
    <div className="workers-by-status">
      <Tabs activeKey={activeStatusTab} onChange={changeActiveStatusTab}>
        {map(tabsKeys, renderTabPane)}
      </Tabs>
    </div>
  )

  function renderTabPane(key: string) {
    const tabKey = getTabKey(key)
    const { badgeStyle, showBadge, title } = tabsSettings[tabKey]
    const label = showBadge ? (
      <Badge style={badgeStyle} count={tabCounters[tabKey]}>
        {title}
      </Badge>
    ) : (
      title
    )
    const gutter = 4
    return (
      <Tabs.TabPane tab={label} key={tabKey}>
        <Row justify="start" gutter={gutter}>
          {tabKey === '_ALL'
            ? map(workersOrderedByName, renderWorkerCol(tabKey, { gutter }))
            : map(workersByStatus[tabKey], renderWorkerCol(tabKey, { gutter }))}
        </Row>
      </Tabs.TabPane>
    )
  }
  function renderWorkerCol(tabKey: TabKey, p: { gutter: number }) {
    // { xs: 8, sm: 16, md: 24, lg: 32 }
    return (worker: MaintenanceWorker & { workerId: string }) => (
      <Col key={worker.workerId} xs={24} sm={12} md={8} lg={8} xl={6} xxl={4} style={{ marginBottom: p.gutter }}>
        <WorkerCard workerId={worker.workerId} worker={worker} hideStatus={tabKey !== '_ALL'} onChangeSector={handleOpenSectorChangeForm} />
      </Col>
    )
  }
}
