import * as React from 'react'
import { Moment } from './moment'
import { DefaultMomentProps } from './types'
import { useTimeIntervalRefresh } from './time-interval-refresh-hook'

export interface DateAndHourProps extends DefaultMomentProps {
  mode?: 'short' | 'long'
}
export const DateAndHour: React.FC<DateAndHourProps> = ({ time, mode }) => {
  useTimeIntervalRefresh()

  if (!time) return null

  const format = mode === 'long' ? 'DD/MM/YYYY HH:mm' : 'DD/MM HH:mm'
  return <Moment format={format}>{time}</Moment>
}
