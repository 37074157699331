import * as React from 'react'
import { MaintenanceTimelineEvent } from '@lenarge/db-schema'
import { SolutionOutlined } from '@ant-design/icons'

import { DateAndHour } from '../moment'
import lenarge, { maintenance } from '../../clients/lenarge'
import { useWorkerOverviewDrawer } from './WorkerOverviewDrawer'

export interface TimelineEventDescriptionProps {
  event: MaintenanceTimelineEvent

  timePreffix?: boolean

  className?: string
  style?: React.CSSProperties
}
export const TimelineEventDescription: React.FC<TimelineEventDescriptionProps> = ({ event, style, className, timePreffix }) => {
  const { time, eventType, procedureId, sectorId, workerId, description } = event
  const eventMeta = lenarge.maintenance().getTimelineEventMeta(eventType)

  const [procedure] = maintenance.useMaintenanceProcedure(procedureId)
  const [sector] = maintenance.useMaintenanceSector(sectorId)
  const [worker] = maintenance.useMaintenanceWorker(workerId)

  const { showWorkerOverviewDrawer } = useWorkerOverviewDrawer()
  const handleWorkerDrawerClick = React.useCallback(() => {
    if (!workerId) return
    showWorkerOverviewDrawer(workerId, 1010)
  }, [workerId, showWorkerOverviewDrawer])

  return (
    <span className={`maintenance-timeline-event-description ${className}`} style={style}>
      {!!timePreffix && (
        <span className="time-preffix">
          <DateAndHour time={time} />
          {': '}
        </span>
      )}
      <span className="type-label">{eventMeta.label}</span>
      {eventType === 'change_description' && <span className="description">{description}</span>}
      {!!sector && <span className="sector">{sector.name}</span>}
      {!!worker && (
        <span className="worker">
          {`${worker.firstName} ${worker.surname}`}
          <SolutionOutlined onClick={handleWorkerDrawerClick} />
        </span>
      )}
      {!!procedure && <span className="procedure">{procedure.name}</span>}
    </span>
  )
}
