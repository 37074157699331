import * as React from 'react'
import { Spin } from 'antd'

import './LastTruckTraceCell.scss'

import { vehicles, iotSascar } from '../../clients/lenarge'
import { CounterFromNow } from '../moment'
import { useChangedClassName } from '../../utils'

export const LastTruckTraceCell: React.FC<{ truckId: string }> = React.memo(({ truckId }) => {
  const [truck, truckLoading] = vehicles.useTruck(truckId)
  const [tracker, trackerLoading] = iotSascar.useSascarTracker(truck?.sascarTrackerDeviceId)
  const loading = truckLoading || trackerLoading

  const speed = tracker?.lastPositionSpeed
  const ignition = tracker?.lastPositionIgnition
  const panelChangedClassName = useChangedClassName([speed, ignition])

  const locationName = tracker?.lastPositionCity
  const locationNameChangedClassName = useChangedClassName([locationName])

  const time = tracker?.lastPositionTime
  const timeChangedClassName = useChangedClassName([time])

  let locationDetail = ''
  if (tracker?.lastPositionPlaces) {
    let placesNames: string[] = []
    for (const placeId in tracker.lastPositionPlaces) {
      const place = tracker.lastPositionPlaces[placeId]
      placesNames.push(place.name)
    }
    locationDetail = placesNames.join(', ')
  }
  const locationDetailChangedClassName = useChangedClassName([locationDetail])

  if (loading)
    return (
      <div>
        <Spin />
      </div>
    )
  if (!tracker?.lastPositionTime) return null
  return (
    <div className="last-trace-truck-cell">
      <div className="header">
        <div className={`panel ${panelChangedClassName}`}>
          <div className="speed">{speed}</div>
          <div className="status">
            <p className="ignition">{ignition ? '🟢' : '🔴'}</p>
            <p className="unit-meter">km/h</p>
          </div>
        </div>
        <div className={`location ${locationNameChangedClassName}`}>{locationName}</div>
        <div className={`time ${timeChangedClassName}`}>
          {'('}
          <CounterFromNow time={time} />
          {')'}
        </div>
      </div>
      <div className={`description ${locationDetailChangedClassName}`}>{locationDetail}</div>
    </div>
  )
})
