import * as React from 'react'
import { Spin, Row, Col } from 'antd'
import { map } from 'lodash'
import { VehicleKind, MaintenanceServiceSectorStatus } from '@lenarge/db-schema'

import './styles.scss'

import { maintenance } from '../../clients/lenarge'
import { useParams, useQueryParams } from '../../utils'
import { SectorServiceCard } from '../../components/maintenance'

export const MaintenanceServicesOfPlaceKanban: React.FC = () => {
  const { placeId } = useParams()
  const [queryParams, setParam] = useQueryParams()
  const vehicleKind = useVehicleKindParam(queryParams)
  const sectorId = queryParams.get('sectorId') || '-LyTkQ9aXuNkJkTt9x26' // default: MECANICA

  const [sectors, loadingSectors] = maintenance.useMaintenanceSectors()

  const [services, loadingServices] = maintenance.useMaintenanceServicesActiveOfPlace(placeId, { vehicleKind })
  const groupedServices = maintenance.useGroupMaintenanceServicesIdsBySectorStatus(services)
  const sectorServices = groupedServices ? groupedServices[sectorId] : undefined

  const loading = loadingServices || loadingSectors

  if (loading)
    return (
      <div className="maintenance-services-of-place-loading">
        <Spin />
      </div>
    )

  return (
    <div className="maintenance-services-of-place-kanban">
      <select value={sectorId} onChange={(ev) => setParam((params) => params.set('sectorId', ev.target.value))}>
        {map(sectors, (sector, optionSectorId) => (
          <option key={optionSectorId} value={optionSectorId}>
            {sector.name}
          </option>
        ))}
      </select>
      <Row className="kanban-cols" gutter={24}>
        <Col className="col-services-executing" xs={24} sm={24} md={8} lg={8} xl={12} xxl={12}>
          {renderStatusServices('EXECUTING', 12, 8)}
        </Col>
        <Col className="col-services-awaiting-for-labor" xs={24} sm={24} md={8} lg={8} xl={6} xxl={8}>
          {renderStatusServices('AWAITING_FOR_LABOR', 24, 12)}
        </Col>
        <Col className="col-services-awaiting-for-parts" xs={24} sm={24} md={8} lg={8} xl={6} xxl={4}>
          {renderStatusServices('AWAITING_FOR_PARTS', 24, 24)}
        </Col>
      </Row>
    </div>
  )

  function renderStatusServices(status: MaintenanceServiceSectorStatus, spanXl: number, spanXxl: number) {
    const statusServices = sectorServices ? sectorServices[status] : undefined
    if (!statusServices) return <p>Nenhum serviço nesta situação</p>
    return (
      <Row gutter={16}>
        <Col span={24}>
          <p>Contagem: {statusServices.length}</p>
        </Col>
        {map(statusServices, (serviceId) => (
          <Col xs={24} sm={12} md={24} lg={24} xl={spanXl} xxl={spanXxl} key={serviceId}>
            <SectorServiceCard serviceId={serviceId} sectorId={sectorId} />
          </Col>
        ))}
      </Row>
    )
  }
}

function useVehicleKindParam(queryParams: URLSearchParams): VehicleKind | undefined {
  const value = queryParams.get('vehicleKind')
  if (value === 'trailer') return 'trailer'
  if (value === 'truck') return 'truck'
  return undefined
}
