import * as React from 'react'
import { Row, Col } from 'antd'
import { AbsencesListReportCard, DaysInWorkReportCard, PayableDaysReportCard } from './LegacyTtruck'

import './styles.scss'

export const Reports: React.FC = () => {
  return (
    <div className="reports-screen">
      <Row gutter={24}>
        <Col sm={24} md={12}>
          <DaysInWorkReportCard />
        </Col>
        <Col sm={24} md={12}>
          <PayableDaysReportCard />
        </Col>
        <Col sm={24} md={12}>
          <AbsencesListReportCard />
        </Col>
      </Row>
    </div>
  )
}
