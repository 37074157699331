import * as React from 'react'
import * as moment from 'moment'
import { message, DatePicker, Button, Card, Tag } from 'antd'
import fileDownload from 'js-file-download'

import lenarge, { useAsyncCall } from '../../../clients/lenarge'

import './styles.scss'

export const AbsencesListReportCard: React.FC = () => {
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')

  const handleRangePickerChange = React.useCallback(
    (dates: [moment.Moment | null, moment.Moment | null] | null) => {
      if (!dates) {
        setStartDate('')
        setEndDate('')
        return
      }
      setStartDate(dates[0]?.format('YYYY-MM-DD') || '')
      setEndDate(dates[1]?.format('YYYY-MM-DD') || '')
    },
    [setStartDate, setEndDate]
  )

  const handleReportSuccess = React.useCallback(
    (csv: string) => {
      message.success('Relatório obtido com sucesso! Começando download...')
      setTimeout(() => {
        const fileName = `lista-de-faltas--${startDate}--a--${endDate}.csv`
        fileDownload(csv, fileName)
      }, 1000)
    },
    [startDate, endDate]
  )

  const handleReportFail = React.useCallback((errorMessage: string) => {
    message.error(errorMessage)
  }, [])

  const payableDaysReport = useAsyncCall(lenarge.drivers().getAbsencesListReportCsv, { onSuccess: handleReportSuccess, onFail: handleReportFail })
  const callPayableDaysReport = payableDaysReport.call

  const handleSubmit = React.useCallback(() => {
    if (!startDate) {
      message.error('Por favor, informe uma data inicial!')
      return
    }
    if (!endDate) {
      message.error('Por favor, informe uma data final!')
      return
    }

    callPayableDaysReport(startDate, endDate)
  }, [startDate, endDate, callPayableDaysReport])

  const disabled = payableDaysReport.requesting || !startDate || !endDate
  return (
    <Card title="Lista de Faltas - Apenas Falta" size="small" extra={<Tag color="red">TTRUCK</Tag>} className="drivers-report-card absences-list">
      <DatePicker.RangePicker onChange={handleRangePickerChange} format="DD/MM/YYYY" placeholder={['Data inicial', 'Data final']} />
      <Button type="primary" onClick={handleSubmit} loading={payableDaysReport.requesting} disabled={disabled}>
        Obter Relatório
      </Button>
    </Card>
  )
}
