import * as React from 'react'
import { SolutionOutlined, ToolOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tabs, Timeline } from 'antd'
import { map, orderBy, groupBy } from 'lodash'
import { MaintenanceServiceSector } from '@lenarge/db-schema'
import moment from 'moment'

import lenarge, { maintenance, vehicles } from '../../clients/lenarge'
import { HumanizeFromNow } from '../moment'
import { ResponsibleDrawer } from '../drawer'
import { TimelineEventDescription } from './TimelineEventDescription'

import './ServiceDrawer.styles.scss'
import { useWorkerOverviewDrawer } from './WorkerOverviewDrawer'

export const ServiceDrawerProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<{ serviceId?: string; showing?: boolean; zIndex?: number }>({})
  const showServiceDrawer = React.useCallback(
    (serviceId: string, zIndex?: number) => {
      setState({ serviceId, zIndex, showing: true })
    },
    [setState]
  )
  const closeServiceDrawer = React.useCallback(() => {
    setState((currentState) => ({ ...currentState, showing: false }))
  }, [setState])
  return (
    <ServiceDrawerContext.Provider value={{ showServiceDrawer, closeServiceDrawer }}>
      {children}
      {state.serviceId && (
        <ServiceDrawer serviceId={state.serviceId} showing={state.showing} zIndex={state.zIndex} onClose={closeServiceDrawer} />
      )}
    </ServiceDrawerContext.Provider>
  )
}

export type ServiceDrawerHook = ServiceDrawerContextValue
export function useServiceDrawer(): ServiceDrawerHook {
  return React.useContext(ServiceDrawerContext)
}

interface ServiceDrawerProps {
  showing?: boolean
  serviceId: string
  onClose: () => void
  zIndex?: number
}
const ServiceDrawer: React.FC<ServiceDrawerProps> = ({ serviceId, showing, onClose, zIndex }) => {
  const [service, loadingService] = maintenance.useMaintenanceService(serviceId)

  const [vehicle, loadingVehicle] = vehicles.useVehicle(service?.vehicleId, service?.vehicleKind)
  // const truckId = service && service.vehicleKind === 'truck' ? service.vehicleId : undefined
  // const [lastTrace, loadingLastTrace] = vehicles.useLastTruckTrace(truckId)

  const vehicleKindMeta = service ? lenarge.vehicles().getVehicleKindMeta(service.vehicleKind) : undefined

  const loading = loadingService || loadingVehicle // || loadingLastTrace

  if (loading || !service) return null

  return (
    <ResponsibleDrawer
      title={`Serviço: ${service.rdprServiceCod}/${service.rdprFilialNumber}`}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={showing}
      zIndex={zIndex}
      className="service-drawer"
    >
      <p>{`Placa: ${vehicle?.licensePlate} ${vehicleKindMeta?.label}`}</p>
      {/* {lastTrace && (
        <p>
          {'Rastreado '}
          <HumanizeFromNow time={lastTrace.time} suffix />
          <br />
          {lastTrace.ignition ? `Ligado à ${lastTrace.speed}km/h` : 'Desligado'}
          <br />
          {lastTrace.locationName}
          {lastTrace.locationDetail && (
            <>
              <br />
              {lastTrace.locationDetail}
            </>
          )}
        </p>
      )} */}
      <p>
        {'Reportado '}
        <HumanizeFromNow time={service.reportTime} suffix />
      </p>
      <Tabs defaultActiveKey="sectors">
        <Tabs.TabPane tab="Setores" key="sectors">
          {map(service.sectors, (sectorService, sectorId) => (
            <p key={sectorId}>
              <ServiceDrawerSectorList sectorId={sectorId} sectorService={sectorService} />
            </p>
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Timeline" key="timeline">
          <ServiceDrawerTimeline serviceId={serviceId} />
        </Tabs.TabPane>
      </Tabs>
    </ResponsibleDrawer>
  )
}

const ServiceDrawerSectorList: React.FC<{ sectorId: string; sectorService: MaintenanceServiceSector }> = ({ sectorService, sectorId }) => {
  const [sector] = maintenance.useMaintenanceSector(sectorId)
  return (
    <>
      {sector?.name || '...'}
      {':'}
      <br />
      {map(sectorService.workers, (worker, workerId) => (
        <ServiceDrawerSectorWorkersList key={workerId} workerId={workerId} />
      ))}
      {map(sectorService.procedures, (procedure, procedureId) => (
        <ServiceDrawerSectorProceduresList
          key={procedureId}
          procedureId={procedureId}
          finishTime={procedure.finishTime}
          abortTime={procedure.abortTime}
          description={procedure.description}
        />
      ))}
    </>
  )
}

const ServiceDrawerSectorProceduresList: React.FC<{ procedureId: string; finishTime?: number; abortTime?: number; description?: string }> = ({
  procedureId,
  finishTime,
  abortTime,
  description,
}) => {
  const [procedure] = maintenance.useMaintenanceProcedure(procedureId)
  const textDecoration = finishTime ? 'line-through #52c41a' : abortTime ? 'line-through #f5222d' : undefined
  return (
    <>
      <span style={{ display: 'block', textDecoration }}>
        <ToolOutlined /> {procedure?.name || '...'}
      </span>
      {description && <span style={{ display: 'block', textDecoration, marginLeft: '15px', fontSize: '10px' }}>{description}</span>}
    </>
  )
}

const ServiceDrawerSectorWorkersList: React.FC<{ workerId: string }> = ({ workerId }) => {
  const [worker] = maintenance.useMaintenanceWorker(workerId)
  const fullName = worker ? `${worker.firstName} ${worker.surname}` : undefined
  const { showWorkerOverviewDrawer } = useWorkerOverviewDrawer()
  const handleWorkerOverviewDrawerOpen = React.useCallback(() => {
    showWorkerOverviewDrawer(workerId, 2000)
  }, [workerId, showWorkerOverviewDrawer])
  return (
    <>
      {'    '}
      <LegacyIcon type="user" theme="twoTone" twoToneColor="#1890FF" /> {fullName || '...'}{' '}
      <SolutionOutlined onClick={handleWorkerOverviewDrawerOpen} />
      <br />
    </>
  )
}

const ServiceDrawerTimeline: React.FC<{ serviceId: string }> = ({ serviceId }) => {
  const [timeline, loading] = maintenance.useMaintenanceTimelineOfService(serviceId)

  const eventsByTime = React.useMemo(() => {
    if (!timeline) return
    const ordered = orderBy(timeline, 'time', 'asc')
    const grouped = groupBy(ordered, (event) => moment(event.time).format('ddd, DD/MM HH:mm'))
    return grouped
  }, [timeline])
  return (
    <Timeline reverse pending={loading}>
      {map(eventsByTime, (events, time) => (
        <Timeline.Item key={time} color="gray">
          {`${time}: `}
          {map(events, (event, index) => (
            <TimelineEventDescription key={index} event={event} />
          ))}
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

interface ServiceDrawerContextValue {
  showServiceDrawer: (serviceId: string, zIndex?: number) => void
  closeServiceDrawer: () => void
}
const ServiceDrawerContext = React.createContext<ServiceDrawerContextValue>({
  showServiceDrawer: () => {},
  closeServiceDrawer: () => {},
})
