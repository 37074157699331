import * as React from 'react'
import { Tag } from 'antd'
import { ServiceStatusMeta } from '@lenarge/api/lib/modules/maintenance/rtdb'

import './ServiceCodeCell.scss'

import { useServiceDrawer } from './ServiceDrawer'
import { CounterFromNow } from '../moment'
import { MaintenanceServiceStatus } from '@lenarge/db-schema'

export interface ServiceCodeCellProps {
  serviceId: string
  status: MaintenanceServiceStatus
  rdprServiceCod?: number
  rdprFilialNumber?: number
  statusChangeTime: number
  sectorsFinishTime?: number
  statusMeta: ServiceStatusMeta
  isEmergencyCare?: boolean
  isUnpredictable?: boolean
  isPriority?: boolean
  deadlineTime?: number
  classificationCode?: number
}

export const ServiceCodeCell: React.FC<ServiceCodeCellProps> = ({
  serviceId,
  rdprServiceCod,
  rdprFilialNumber,
  statusChangeTime,
  sectorsFinishTime,
  statusMeta,
  isEmergencyCare,
  isUnpredictable,
  isPriority,
  deadlineTime,
  classificationCode,
}) => {
  const { showServiceDrawer } = useServiceDrawer()
  const handleCellClick = React.useCallback(() => {
    showServiceDrawer(serviceId)
  }, [serviceId, showServiceDrawer])
  const isFinished = statusMeta.statusDesc === 'Liberado'
  const emergencyClassName = !isFinished && isEmergencyCare ? 'emergency-care' : ''
  const unpredictableClassName = !isFinished && isUnpredictable ? 'unpredictable' : ''
  const priorityClassName = !isFinished && isPriority ? 'priority' : ''
  return (
    <div className={`service-code-cell ${emergencyClassName} ${unpredictableClassName} ${priorityClassName}`} onClick={handleCellClick}>
      <span className="code">
        {rdprServiceCod}
        {'/'}
        {rdprFilialNumber}
      </span>
      <span className="time">
        {'('}
        <CounterFromNow time={sectorsFinishTime || statusChangeTime} />
        {')'}
      </span>
      <span className="status-tag">
        <Tag color={statusMeta.statusColor.tagColorName}>{statusMeta.statusDesc}</Tag>
        {!isPriority && !isUnpredictable && deadlineTime && !isFinished && (
          <Tag color="gold">
            <CounterFromNow time={deadlineTime} />
          </Tag>
        )}
        {isPriority && <Tag color="red">P {!isUnpredictable && deadlineTime && !isFinished && <CounterFromNow time={deadlineTime} />}</Tag>}
        {isUnpredictable && <Tag color="gold">S/P</Tag>}
        {isEmergencyCare && <Tag color="purple">SOC</Tag>}
        {classificationCode === 213 && <Tag color="cyan">Veí. Novo</Tag>}
        {classificationCode === 212 && <Tag color="cyan">Veí. Vendido</Tag>}
        {classificationCode === 214 && <Tag color="cyan">Veí. Filiado</Tag>}
      </span>
    </div>
  )
}
