import * as React from 'react'
import { ProjectOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import { size } from 'lodash'

import lenarge, { maintenance, vehicles } from '../../clients/lenarge'
import { CounterFromNow, HumanizeFromNow } from '../moment'

import './SectorServiceCard.styles.scss'
import { useChangedClassName } from '../../utils'
import { useServiceDrawer } from './ServiceDrawer'

export interface SectorServiceCardProps {
  serviceId: string
  sectorId: string
}
export const SectorServiceCard: React.FC<SectorServiceCardProps> = ({ serviceId, sectorId }) => {
  const [service, loadingService] = maintenance.useMaintenanceService(serviceId)
  const [vehicle, loadingVehicle] = vehicles.useVehicle(service?.vehicleId, service?.vehicleKind)

  const vehicleKindMeta = service && lenarge.vehicles().getVehicleKindMeta(service.vehicleKind)

  const { showServiceDrawer } = useServiceDrawer()
  const handleServiceDrawer = React.useCallback(() => showServiceDrawer(serviceId), [showServiceDrawer, serviceId])

  const sectors = service?.sectors
  const sectorService = (sectors && sectors[sectorId]) || undefined

  const loading = loadingService || loadingVehicle

  const sectorStatus = sectorService?.status
  const statusChangeTime = sectorService?.statusChangeTime
  const statusChangeTimeChangedClassName = useChangedClassName([statusChangeTime, sectorStatus])

  const workers = sectorService?.workers
  const workersCount = React.useMemo(() => size(workers), [workers])
  const workersCountChangedClassName = useChangedClassName([workersCount])

  const procedures = sectorService?.procedures
  const proceduresCount = React.useMemo(() => size(procedures), [procedures])
  const proceduresCountChangedClassName = useChangedClassName([proceduresCount])

  const othersPendingSectorsCount = React.useMemo(() => {
    if (!sectors) return 0
    let count = 0
    for (const sId in sectors) {
      if (sId === sectorId) continue
      const s = sectors[sId]
      if (s.status === 'FINISHED') continue
      count += 1
    }
    return count
  }, [sectors, sectorId])
  const othersPendingSectorsCountChangedClassName = useChangedClassName([othersPendingSectorsCount])

  const changedSomethingClassName = useChangedClassName([statusChangeTime, sectorStatus, workersCount, proceduresCount])

  return (
    <Card className="card-sector-service" loading={loading} onClick={handleServiceDrawer}>
      <div className={`vehicle-id ${changedSomethingClassName}`}>
        <span className="license-plate">{vehicle?.licensePlate}</span>
        {vehicleKindMeta && <span className="vehicle-kind">{vehicleKindMeta.label}</span>}
      </div>
      <div className={`counter ${statusChangeTimeChangedClassName}`}>
        <CounterFromNow time={statusChangeTime} />
      </div>
      <div className="details">
        <div>
          {'Reportado '}
          <HumanizeFromNow time={service?.reportTime} suffix />
        </div>
        <div>
          <div className={workersCountChangedClassName}>
            <TeamOutlined />
            {workersCount}
          </div>
          <div className={proceduresCountChangedClassName}>
            <ToolOutlined />
            {proceduresCount}
          </div>
          {!!othersPendingSectorsCount && (
            <div className={othersPendingSectorsCountChangedClassName}>
              <ProjectOutlined />
              {'+'}
              {othersPendingSectorsCount}
            </div>
          )}
        </div>
      </div>
      {service?.rdprServiceCod && <div className="rdpr-service-id">{service?.rdprServiceCod}</div>}
    </Card>
  )
}
